



















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import wechatImage from '@/components/wechatImageText/wechatImageText.vue';
import wechatPicture from '@/components/wechatImageText/wechatPicture.vue';
@Component({
  components: {
    wechatImage,
    wechatPicture
  }
})
export default class index extends Vue {
  @Prop({ default: true }) public title!: String;
  @Prop({ default: true }) public types!: String;
  @Prop({ default: false }) public ishowImage!: Boolean;

  public dialogFormVisible: boolean = false;//是否显示弹框
  public currow: any = []//接受子组件的值

  @Watch('currow', { deep: true })
  handleWatch() {
    console.log('监听上面数组的内容')
  }

  public async created(): Promise<void> {

  }
  //得到子组件传过来的值
  @Emit('getChild')
  public getChild(val: any): void {
    this.currow = val
    this.dialogFormVisible = false
  }
  public checkCont(): any {
    this.$nextTick(() => {
      this.dialogFormVisible = true
    })

    // let child =  (this.$refs.child as wechatImage)
    // console.log(this.$refs.child)
    // child.getList()
  }
  public cancel(): any {
    this.dialogFormVisible = false
  }

}
