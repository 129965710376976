






















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component({
  components: {
      
  }
})
export default class wechatPicture extends Vue {
  @Prop({ default: true }) private dialogFormVisible!: boolean; 
  public formImage: any = {};
  private pagination: any = {
    pageSize: 3,
    total: 0,
    pageNum: 1
  };
  private tableData: any[] = [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
    }];
   private currentRow: any = null;
      
  @Emit('selectChange')
  public cancal(): Boolean {
    let isDone: boolean = false;
    return isDone
  }
}
